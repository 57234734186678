<template>
  <div class="combobox-selected-options-column">
    <p><slot name="list-title"></slot></p>
    <ul class="unstyled-list">
      <selected-filter-tag
        v-for="option in selectedOptions"
        :key="`selection-${option.value}`"
        :filter="option"
        @remove="deleteOption"
      />
    </ul>
  </div>
</template>

<script>
import SelectedFilterTag from "@/programs/components/buttons/SelectedFilterTag.vue";
export default {
  props: {
    selectedOptions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["delete-option"],
  components: {
    SelectedFilterTag,
  },
  methods: {
    deleteOption(value) {
      this.$emit("delete-option", value);
    },
  },
};
</script>
