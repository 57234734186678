import axios from "axios";

import { genericMixin } from "./generic.js";

import {
  userLevelRequestUrls,
  userLevelRequestReferenceReflectionsUrl,
  userLevelRequestFileAttachmentUrl,
  userLevelRequestFileDetachmentUrl,
} from "../urls";
import { RESPONSE_STATUSES } from "../utils/constants.js";

const initialState = {
  isSaving: false,
  page: 0,
  levelUpRequest: {
    id: null,
    learning_objective_level: {},
    learning_objective_level_id: null,
    main_text: "",
    status: "",
    user_id: null,
    supplemental_text: "",
    submissions: [],
    files_as_json: [],
    url_attachments: [],
  },
};

export const levelUpRequestModule = {
  namespaced: true,
  state: () => ({
    ...initialState,
  }),
  getters: {
    ...genericMixin.getters,
    requestedLevel(state) {
      return state.levelUpRequest.to_level != null
        ? state.levelUpRequest.to_level
        : null;
    },
    dimension(state) {
      return state.levelUpRequest.id ? state.levelUpRequest.objective : null;
    },
    competency(state, getters) {
      return getters.dimension ? getters.dimension.competency : null;
    },
  },
  mutations: {
    setLevelUpRequestData(state, data) {
      state.levelUpRequest = data;
    },
    updateReferencedReflections(state, reflections) {
      state.levelUpRequest.submissions = reflections;
    },
    unreferenceReflections(state, { submissionIds }) {
      submissionIds.forEach((id) => {
        let index = state.levelUpRequest.submissions.findIndex(
          (submission) => submission.id === id,
        );
        if (index >= 0) {
          state.levelUpRequest.submissions.splice(index, 1);
        }
      });
    },
    updateFilesAsJson(state, filesAsJson) {
      state.levelUpRequest.files_as_json = filesAsJson;
    },
    updateUrlAttachments(state, urlAttachments) {
      state.levelUpRequest.url_attachments = urlAttachments;
    },
    updateSupplementalText(state, supplementalText) {
      state.levelUpRequest.supplemental_text = supplementalText;
    },
    updateMainText(state, mainText) {
      state.levelUpRequest.main_text = mainText;
    },
    jumpToPage(state, number) {
      state.page = number;
      window.scrollTo(0, 0);
    },
    setSaving(state, isSaving) {
      state.isSaving = isSaving;
    },
  },
  actions: {
    ...genericMixin.actions,
    fetchLevelUpRequestData({ dispatch, commit }, { programId, objectId }) {
      const urlParams = { programId, objectId, id: objectId };
      return dispatch("fetchInstanceData", {
        urls: userLevelRequestUrls,
        data: urlParams,
      }).then((data) => {
        commit("setLevelUpRequestData", data);
      });
    },
    saveLevelUpRequest({ commit, dispatch }, data) {
      commit("setSaving", true);
      return dispatch("saveInstanceData", {
        data: data,
        urls: userLevelRequestUrls,
      })
        .then((response) => {
          setTimeout(() => commit("setSaving", false), 500);
          commit("setLevelUpRequestData", response.instance);
          return response;
        })
        .catch((error) => {
          setTimeout(() => commit("setSaving", false), 500);
          throw new Error(error);
        });
    },
    deleteLevelUpRequest({ dispatch }, data) {
      return dispatch("deleteInstanceData", {
        data,
        urls: userLevelRequestUrls,
      });
    },
    updateReferencedReflections(
      { commit },
      { programId, objectId, reflections },
    ) {
      commit("setSaving", true);
      const url = userLevelRequestReferenceReflectionsUrl.stringify({
        programId,
        objectId,
      });
      return axios
        .post(url, { reflections })
        .then((response) => {
          setTimeout(() => commit("setSaving", false), 500);
          commit("updateReferencedReflections", response.data);
          return { data: response.data, status: response.status, errors: [] };
        })
        .catch((error) => {
          setTimeout(() => commit("setSaving", false), 500);
          return {
            data: {},
            status: error.response.status,
            errors: error.response.data,
          };
        });
    },
    deleteFileAttachment({ commit }, { programId, objectId, id }) {
      commit("setSaving", true);
      const url = userLevelRequestFileDetachmentUrl.stringify({
        programId,
        objectId,
      });
      let formData = new FormData();
      formData.append("id", id);
      return axios
        .post(url, formData)
        .then((response) => {
          setTimeout(() => commit("setSaving", false), 500);
          return response.data;
        })
        .catch((error) => {
          setTimeout(() => commit("setSaving", false), 500);
          throw new Error(error);
        });
    },
    async saveFileAttachments(
      { commit, dispatch },
      { programId, objectId, files },
    ) {
      commit("setSaving", true);
      const response = await dispatch("sendAttachments", {
        programId,
        objectId,
        files,
      }).then((data) => {
        setTimeout(() => commit("setSaving", false), 500);
        commit("updateFilesAsJson", data.files_as_json);
        return data;
      });
      return response;
    },
    async sendAttachments({ commit }, { programId, objectId, files }) {
      commit("setSaving", true);
      const url = userLevelRequestFileAttachmentUrl.stringify({
        programId,
        objectId,
      });
      let formData = new FormData();
      files.forEach((f) => {
        formData.append("files[]", f);
      });
      return axios
        .post(url, formData)
        .then((response) => {
          setTimeout(() => commit("setSaving", false), 500);
          return response.data;
        })
        .catch((error) => {
          setTimeout(() => commit("setSaving", false), 500);
          throw new Error(error);
        });
    },
    async removeFileAttachment(
      { commit, dispatch },
      { programId, objectId, id },
    ) {
      commit("setSaving", true);
      const response = await dispatch("deleteFileAttachment", {
        programId,
        objectId,
        id,
      }).then((data) => {
        setTimeout(() => commit("setSaving", false), 500);
        if (data.status === RESPONSE_STATUSES.success) {
          commit("updateFilesAsJson", data.files_as_json);
        }
        return data;
      });
      return response;
    },
  },
};
