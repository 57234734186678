<template>
  <div class="selected-options-wrapper">
    <spire-empty-state
      v-if="!selectedOptions.length"
      :message="emptyStateMessage"
    />
    <ul v-else class="combobox-selected-options-list flex">
      <selected-filter-tag
        v-for="option in selectedOptions"
        :key="`selection-${option.value}`"
        :filter="option"
        @remove="deleteOption"
      />
    </ul>
  </div>
</template>

<script>
import SelectedFilterTag from "@/programs/components/buttons/SelectedFilterTag.vue";
export default {
  props: {
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    emptyStateMessage: {
      type: String,
      default: "No options selected yet!",
    },
  },
  emits: ["delete-option"],
  components: {
    SelectedFilterTag,
  },
  methods: {
    deleteOption(value) {
      this.$emit("delete-option", value);
    },
  },
};
</script>
