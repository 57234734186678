import debounce from "lodash/debounce";

import { genericMixin } from "./generic.js";
import { LEVEL_REQUEST_STATUS } from "../utils/constants.js";
import { releaseLevelUpRequests } from "../services/LevelRequestService.js";

import { programLevelRequestUrls } from "../urls.js";
import { ADMIN_LEVEL_REQUEST_ROUTES } from "@/programs/route.ts";

const DEBOUNCE_MILLIS = 300;

export const levelUpRequestsModule = {
  namespaced: true,
  state: () => ({
    processedRequests: {},
    pendingRequests: {},
  }),
  getters: {
    ...genericMixin.getters,
    completedRequests(state, getters, rootState, rootGetters) {
      const { data = [] } = state.processedRequests;
      return data.map((request) => ({
        ...request,
        url: ADMIN_LEVEL_REQUEST_ROUTES.detail.path({
          programId: rootGetters.programId,
          levelRequestId: request.id,
        }),
      }));
    },
    completedPagination(state) {
      return state.processedRequests.meta || {};
    },
    todoRequests(state, getters, rootState, rootGetters) {
      const { data = [] } = state.pendingRequests;
      return data.map((request) => ({
        ...request,
        url: ADMIN_LEVEL_REQUEST_ROUTES.review.path({
          programId: rootGetters.programId,
          levelRequestId: request.id,
        }),
      }));
    },
    changesRequestedRequests(state) {
      const { data = [] } = state.processedRequests;
      return data.filter(
        (request) => request.status === LEVEL_REQUEST_STATUS.changesRequested,
      );
    },
    approvedRequests(state) {
      const { data = [] } = state.processedRequests;
      return data.filter(
        (request) => request.status === LEVEL_REQUEST_STATUS.approved,
      );
    },
    todoPagination(state) {
      return state.pendingRequests.meta || {};
    },
  },
  actions: {
    ...genericMixin.actions,
    async releaseLevelUpRequests({ getters }, requests) {
      const response = await releaseLevelUpRequests(
        getters.programId,
        requests,
      );
      return response;
    },
    fetchRequests: debounce(function ({ dispatch }, params) {
      return dispatch("loadObjectList", {
        urls: programLevelRequestUrls,
        mutation: "setPendingRequests",
        params,
      });
    }, DEBOUNCE_MILLIS),
    fetchPendingRequests: debounce(function ({ dispatch }, params) {
      return dispatch("loadObjectList", {
        urls: programLevelRequestUrls,
        mutation: "setPendingRequests",
        params: {
          ...params,
          statuses: [
            LEVEL_REQUEST_STATUS.pending,
            LEVEL_REQUEST_STATUS.inReview,
          ],
        },
      });
    }, DEBOUNCE_MILLIS),
    fetchProcessedRequests: debounce(function ({ dispatch }, params) {
      return dispatch("loadObjectList", {
        urls: programLevelRequestUrls,
        mutation: "setProcessedRequests",
        params: {
          ...params,
          "statuses!": [
            LEVEL_REQUEST_STATUS.pending,
            LEVEL_REQUEST_STATUS.inReview,
          ],
        },
      });
    }, DEBOUNCE_MILLIS),
  },
  mutations: {
    setPendingRequests(state, { data }) {
      state.pendingRequests = data;
    },
    setProcessedRequests(state, { data }) {
      state.processedRequests = data;
    },
  },
};
