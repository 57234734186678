<template>
  <ul class="form-field-errors_spire mb-3" aria-live="polite">
    <li v-for="error in errors" :key="error" class="form-field-error_spire">
      {{ error }}
    </li>
  </ul>
</template>
<script>
export default {
  name: "SpireErrorList",
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
