<template>
  <li class="selected-filter-tag__list-item">
    <spire-tag @click="remove" :title="filter.label" is-removable />
  </li>
</template>

<script>
export default {
  name: "SelectedFilterTag",
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: ["remove"],
  methods: {
    remove() {
      this.$emit("remove", this.filter);
    },
  },
};
</script>
