<template>
  <span v-bind="$attrs" class="spire-tag" :class="[classes]">
    <slot name="tag-number" v-if="hasTagNumber">
      <span class="spire-tag__tag-number">{{ tagNumber }}</span>
    </slot>
    <slot name="icon-left">
      <fa-icon v-if="hasLeftIcon" :icon="iconLeft" />
    </slot>
    <slot>
      <span class="spire-tag__title">{{ title }}</span>
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    iconLeft: {
      type: String,
      default: "",
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    tagNumber: {
      type: [String, Number],
      default: "",
    },
    variant: {
      type: String,
      default: "default",
    },
  },
  computed: {
    hasLeftIcon() {
      return this.iconLeft != "";
    },
    hasTagNumber() {
      return this.tagNumber != "";
    },
    variantClass() {
      return `spire-tag--${this.variant}`;
    },
    classes() {
      return [
        this.variantClass,
        { "spire-tag--numbered": this.hasTagNumber },
        { "spire-tag--removable": this.isRemovable },
        { "spire-tag--has-icon": this.hasLeftIcon },
      ];
    },
  },
};
</script>
